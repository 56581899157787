import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import { BrandModel } from "../../../model/brandModel"
import GenericPreview from "../generic-preview"

const BrandPreview = ({ brand, market }) => {
  const { t } = useTranslation()
  return (
    <GenericPreview
      image={brand.cover_image?.childImageSharp}
      imageAltTranslationKey={brand.name.key}
      titleTranslationKey={brand.name.key}
      linkToProductsPageTextTranslationKey={"PRODUCTS-VIEW_ALL_PRODUCTS"}
      linkToDetailPagePath={brand.getDetailPageUrl()}
      linkToProductsPagePath={brand.getProductPageUrl({ market })}
      descriptionTranslationKey={brand.description.key}
      shortDescriptionTranslationKey={brand.short_description.key}
      moreAboutThisTranslatedString={t("BRANDS-MORE_ABOUT_THIS_BRAND")}
    />
  )
}

BrandPreview.propTypes = {
  brand: PropTypes.instanceOf(BrandModel),
}

export default BrandPreview
